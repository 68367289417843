import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonModule } from '@net-ui/button';
import { DialogComponent } from '@net-ui/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  templateUrl: './dialog-version-new.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogComponent, ButtonModule, TranslateModule],
})
export class DialogVersionNewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  public onRefreshClick(): void {
    window.location.reload();
  }
}
