import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@net-ui/button';
import { IconModule } from '@net-ui/icon';

@Component({
  selector: 'net-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, IconModule, ButtonModule, MatDialogModule],
})
export class DialogComponent implements OnInit {
  @Input() isFooterVisible = true;
  @Input() isCloseVisible = true;

  constructor(private dialogRef: MatDialog) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.closeAll();
  }
}
