<net-dialog [isCloseVisible]="false">
  <ng-container id="header">
    <h3 class="text-xl">{{ 'dialog-version-new.headline' | translate }}</h3>
  </ng-container>

  <p>{{ 'dialog-version-new.content' | translate }}</p>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button netButton type="button" (click)="onRefreshClick()">
        {{ 'dialog-version-new.cta' | translate }}
      </button>
    </div>
  </ng-container>
</net-dialog>
