import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/internship-form/internship-form.module').then(
        (m) => m.InternshipFormModule
      ),
  },
  {
    path: 'abschlussprojekte',
    loadChildren: () =>
      import('./modules/student-projects/student-projects.module').then(
        (m) => m.StudentProjectsModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
