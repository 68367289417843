import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogVersionNewComponent } from '@net-dialogs/dialog-version-new';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private dialog: MatDialog) {}

  handleError(error: unknown): void {
    if (this.isChunkLoadError(error)) {
      this.dialog.open<DialogVersionNewComponent>(DialogVersionNewComponent, {
        disableClose: true,
      });
    }

    console.log(error);
  }

  private isChunkLoadError(error: unknown): boolean {
    return (
      error instanceof Error &&
      error.message.indexOf('Failed to fetch dynamically imported module') !==
        -1
    );
  }
}
